import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const SupportPage = () => {
  return (
    <Layout>
      <Seo 
        title="Support" 
        description="Get help from our team"
      />
    </Layout>
  )
}

export default SupportPage
